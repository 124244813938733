import { writable as localStoreWritable } from 'svelte-local-storage-store';

const LOCAL_STORAGE_KEY = 'the-users-will';
export type LocalStorageModel = {
    showCookieBanner: boolean;
    cookiesAllowed: boolean;
    instaEmbed: 'not_yet_asked' | 'allowed' | 'forbidden';
    showNewsletterModal: boolean;
};

const initialValue: LocalStorageModel = {
    showCookieBanner: true,
    cookiesAllowed: false,
    instaEmbed: 'not_yet_asked',
    showNewsletterModal: true,
};

// First param `preferences` is the local storage key.
// Second param is the initial value.
export const localStore = localStoreWritable(LOCAL_STORAGE_KEY, initialValue);
